import React, {FC, useLayoutEffect, useState} from 'react';
import './registration.css';
import RegistrationForm from './RegistrationForm';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import RegistrationSuccessMessage from "./RegistrationSuccessMessage";
import HeaderSecondary from "../../components/Header/HeaderSecondary";
import {ReactSVG} from "react-svg";
import arrow from '../../assets/images/icons/arrow.svg';

interface Props {
}

const RegistrationPage: FC<Props> = () => {
  const {t} = useTranslation();
  // const [token, setToken] = useState<string|null>(window.localStorage.getItem('token') || '');
  const [successForm, setSuccessForm] = useState(false);

  useLayoutEffect(() => {
    //@ts-ignore
    window.scrollTo({top: 0, behavior: "instant"})
  }, []);

  return (
    <div className='registration-page'>
      <HeaderSecondary/>
      <main className={'registration-main'}>
        {successForm && <RegistrationSuccessMessage/>}
        <div className='d-flex justify-content-start'>
          <Link to='/' className='d-flex text-semiBold text-decoration-underline'>
            <ReactSVG src={arrow} className='react-icon back-arrow'/>
            <span className='ps-3'>{t('BACK_TO_SITE')}</span>
          </Link>
        </div>
        {!successForm && <RegistrationForm setSuccessForm={setSuccessForm}/>}
      </main>
    </div>

  )
};

export default RegistrationPage;
