import React, {FC} from 'react';
import './footer.css';
import logo from '../../assets/images/logo_dark.png';
import {privacy_href, support_href} from "../../data/constants";
import {useTranslation} from "react-i18next";

interface Props {
}

const Footer: FC<Props> = () => {
  const {t} = useTranslation();
  return (
    <footer>
      <div className="footer">
        <div className='d-flex align-items-center'>
          <img src={logo} alt="logo" className='footer-logo'/>
          <a href={privacy_href} target='_blank' className='text-14 text-decoration-underline'>{t('PRIVACY')}</a>
        </div>
        <div className="header-separator d-block d-lg-none"/>
        <div className='d-flex flex-column flex-lg-row align-items-center'>
          <div className='text-muted-14 mb-3 m-lg-0' style={{width: 173}}>{t('FOOTER_TEXT')}</div>
          <a href={`mailto:${support_href}`} target='_blank' className='btn btn-outline-primary ms-0 ms-lg-4'>
            <div>{t('WRITE_TO_SUPPORT')}</div>
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;