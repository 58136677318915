import React, {FC} from 'react';

import './header.css'
import './header-media.css'

import {useTranslation} from "react-i18next";
import LangSelect from "./LangSelect";
import Logo from "../Logo";
import {login_href, privacy_href, support_href} from "../../data/constants";
import {Link} from "react-router-dom";

interface Props {
}

const HeaderSecondary: FC<Props> = () => {
  const {t} = useTranslation();
  return (
    <header>
      <div className="header header-secondary">
        <div className='header-left'>
          <div className='d-flex align-items-center'>
            <Link to={'/'}>
              <Logo className='header-logo'/>
            </Link>
            <div className="header-separator d-none d-lg-block"/>
            <div className='text-muted-14' style={{width: 200}}>{t('HEADER_TEXT')}</div>
          </div>
        </div>
        <div className='d-flex align-items-center flex-grow-1 justify-content-between pt-3 pt-xl-0'>
          <LangSelect className='order-3 order-xl-0'/>
          <div className='d-flex align-items-center'>
            {/*<a href={privacy_href} target='_blank'*/}
            {/*   className='text-muted-14 text-decoration-underline  d-none d-lg-block'>{t('PRIVACY')}</a>*/}
            <div className='text-muted-14 mb-3 m-lg-0 d-none d-xl-block'
                 style={{width: 184}}>{t('FOOTER_TEXT')}</div>
            <div className="header-separator  d-none d-lg-block"/>
            <a href={`mailto:${support_href}`} target='_blank' className='btn btn-outline-primary ms-3 order-1 order-xl-0'>
              <div>
                <span className='d-none d-sm-inline'>{t('WRITE_TO_SUPPORT')}</span>
                <span className='d-sm-none d-inline'>{t('SUPPORT')}</span>
              </div>
            </a>
            <a href={login_href} target={'_blank'} className='btn btn-outline-primary ms-3 ms-xl-4'>
              <div>{t('LOGIN')}</div>
            </a>
          </div>
        </div>
      </div>
    </header>
  );
}

export default HeaderSecondary;