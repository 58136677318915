import React, {FC, useCallback} from 'react';

import './header.css'
import './header-media.css'

import {useTranslation} from "react-i18next";
import LangSelect from "./LangSelect";
import Logo from "../Logo";
import useScrollspy from "../../hook/useScrollspy";
import {Link, useNavigate} from "react-router-dom";
import {login_href} from "../../data/constants";

const menus = [
  {id: 'section-1', title: 'MENU_1'},
  {id: 'section-2', title: 'MENU_2'},
  {id: 'section-3', title: 'MENU_3'},
  // {id: 'section-4', title: 'FAQ'}
]

interface Props {
}

const Header: FC<Props> = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const activeId = useScrollspy(['section-1', 'section-2', 'section-3' ], 200);

  const handleRegister = useCallback(() => {
    navigate('/registration')
  }, [navigate]);

  const handleClick = (id: string) => (e: any) => {
    e.preventDefault();
    const element: any = document.getElementById(id);
    const offset = 100;
    const bodyRect: any = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  };

  return (
    <header>
      <div className="header">
        <div className='header-left'>
          <div className='d-flex align-items-center'>
            <Link to={'/'}>
              <Logo className='header-logo'/>
            </Link>
            <div className='text-muted-14' style={{width: 200}}>{t('HEADER_TEXT')}</div>
            <LangSelect className='d-none d-xl-flex' />
          </div>
        </div>
        <div className='header-menu'>
          <nav>
            <ul className='ps-0'>
              {menus.map((item, i) => {
                return <li key={i}>
                  <a
                    href={`#${item.id}`}
                    className={`${item.id === activeId ? 'active' : ''}`}
                    onClick={handleClick(item.id)}
                  >
                    {t(item.title)}
                  </a>
                </li>
              })}
            </ul>
          </nav>
        </div>
        <div className='header-right'>
          <button className='btn btn-primary' type='button' onClick={handleRegister}>
            <div>{t('REGISTER')}</div>
          </button>
          <a href={login_href} target={'_blank'} className='btn btn-outline-primary ms-3 ms-lg-4'>
            <div>{t('LOGIN')}</div>
          </a>
          <LangSelect className='d-flex d-xl-none ms-3 ms-lg-4' />
        </div>
      </div>
    </header>
  );
}

export default Header;