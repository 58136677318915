import React, {FC, useCallback} from 'react';
import './section_1.css';
import './section-media.css';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import youtube from './assets/youtube.png'
import man from './assets/man.png'
import bg from './assets/bg.png'
import {CircleButton} from "../../../components/button";

interface Props {
}

const Section_1: FC<Props> = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const handleRegistration = useCallback(() => {
    navigate('/registration')
  }, [navigate]);

  return (
    <section  id='section-1'>

      <div className='container section-1'>
        <img src={man} alt="man" className='section-1-img'/>
        <div className='flex-grow-1 position-relative'>
          <h1 dangerouslySetInnerHTML={{__html: t('SECTION1_TITLE_1') || ''}}/>
        </div>
        <div className='section-1-text-2'>
          <h2 >
            <span dangerouslySetInnerHTML={{__html: t('SECTION1_TITLE_2_1') || ''}}/>
            <img src={youtube} alt="youtube"/>
            <span className='text-bordered'>{t('SECTION1_TITLE_2_2')}</span>
          </h2>
          <CircleButton onClick={handleRegistration} className='section-1-btn'/>
        </div>
      </div>

    </section>
  );
}

export default Section_1;