import React, {ButtonHTMLAttributes, FC} from 'react';
import {useTranslation} from "react-i18next";
import {ReactSVG} from "react-svg";
import arrow from '../../assets/images/icons/arrow.svg'

interface Props extends ButtonHTMLAttributes<any> {
  text?: string;
  className?: string;
}

const CircleButton: FC<Props> = ({text, className, ...props}) => {
  const {t} = useTranslation();
  return (
    <button className={`circle-btn ${className || ''}`} {...props} type={props.type || 'button'}>
      <div className="circle-btn-content">
        <div className='circle-btn-arrow-wrap'>
          <div className="circle-btn-text">{t(text || 'REGISTER')}</div>
          <ReactSVG src={arrow} className='react-icon circle-btn-arrow'/>
        </div>
      </div>
    </button>
  );
}

export default CircleButton;