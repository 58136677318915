import React, {FC} from 'react';
import {useTranslation} from "react-i18next";
import {login_href, support_href} from "../../data/constants";

interface Props {
}

const RegistrationSuccessMessage: FC<Props> = () => {
  const {t} = useTranslation();
  return (
    <div>
      <h3 className='mt-3 mb-20'>{t('SUCCESS_TITLE')}</h3>
      <div className='mb-40'>
        <span>{t('SUCCESS_TEXT_1')} </span>
        <a href={login_href} className='text-semiBold text-decoration-underline' target='_blank'>{t('SUCCESS_TEXT_2')}</a>
      </div>
    </div>
  );
}

export default RegistrationSuccessMessage;