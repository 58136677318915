import React, {FC} from 'react';
import Section_1 from "./Section_1";
import Section_2 from "./Section_2";
import Section_3 from "./Section_3";
import Section_4 from "./Section_4";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Section_5 from "./Section_5";

interface Props {
}


const HomePage: FC<Props> = () => {
  return (
    <>
      <Header/>
      <main className='container-fluid'>
        <Section_1/>
        <Section_2/>
        <Section_3/>
        {/*<Section_4/>*/}
        <Section_5/>
      </main>
      <Footer/>
    </  >
  );
}

export default HomePage;