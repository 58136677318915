import React, {FC, useCallback, useRef, useState} from 'react';
import './section_2.css';
import './section-media.css';
import {useTranslation} from "react-i18next";
import Slider from "react-slick";
import slide_1 from './assets/slide_1.png';
import slide_2 from './assets/slide_2.png';
import slide_3 from './assets/slide_3.png';
import slide_4 from './assets/slide_4.png';
import slide_5 from './assets/slide_5.png';

const slides = [slide_1, slide_2, slide_3, slide_4, slide_5];

interface Props {
}

const Section_2: FC<Props> = () => {
  const {t} = useTranslation();
  const _slider: any = useRef(null);
  const _sliderImg: any = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [nextSlide, setNextSlide] = useState(0);
  const settings = {
    dots: false,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    centerMode: true,
    centerPadding: '0px',
    vertical: true,
    beforeChange: function (currentSlide: number, nextSlide: number) {
      setCurrentSlide(currentSlide);
      setNextSlide(nextSlide);
    },
    afterChange: function (currentSlide: number) {
      setCurrentSlide(currentSlide);
    }
  };
  const settingsImg = {
    dots: false,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    loop: true,
  };


  const handleClick = useCallback((idx: number) => () => {
    if (idx === currentSlide) return;
    if (currentSlide === 0 && idx === 4) {
      _slider.current?.slickPrev()
      _sliderImg.current?.slickPrev()
    } else if (currentSlide === 4 && idx === 0) {
      _slider.current?.slickNext();
      _sliderImg.current?.slickNext()
    } else if (idx > currentSlide) {
      _slider.current?.slickNext()
      _sliderImg.current?.slickNext()
    } else {
      _sliderImg.current?.slickPrev()
      _slider.current?.slickPrev()
    }
  }, [currentSlide]);

  const paginationClick = useCallback((dir: 1 | -1, length = 1) => () => {
    for (let i = 0; i < length; i++) {
      setTimeout( () => {
        if(dir === 1) {
          _slider.current?.slickNext()
          _sliderImg.current?.slickNext()
        } else {
          _sliderImg.current?.slickPrev()
          _slider.current?.slickPrev()
        }
      },i * 550)

    }
  }, []);


  return (
    <section id='section-2'>
      <div className='container section-2'>
        <h3 className='text-center' dangerouslySetInnerHTML={{__html: t('SECTION2_TITLE') || ''}}/>
        <div className='align-items-center d-none d-xl-flex'>
          <Slider {...settings} ref={_slider} className='section2-slider'>
            {Array(5).fill({}).map((item: any, i: number) => (
              <div key={i} className={`section3-slick-item`} onClick={handleClick(i)}>
                {t(`SECTION2_TEXT_${i + 1}`)}
              </div>
            ))}
          </Slider>
          {/*<div className='section2-slider'>*/}
          {/*  {Array(5).fill({}).map((item: any, i: number) => (*/}
          {/*    <div key={i} className={`section3-slick-item${i === currentSlide ? ' current' : ''}`} onClick={handleClick(i)}>*/}
          {/*      {i}*/}
          {/*      {t(`SECTION2_TEXT_${i + 1}`)}*/}
          {/*    </div>*/}
          {/*  ))} */}
          {/*</div>*/}
          <div className='section3-pagination'>
            <div className="section3-pagination-circle small" onClick={paginationClick(-1, 2)}/>
            <div className="section3-pagination-circle" onClick={paginationClick(-1)}/>
            <div className="section3-pagination-center accent-glow">0{nextSlide + 1}</div>
            <div className="section3-pagination-circle" onClick={paginationClick(1)}/>
            <div className="section3-pagination-circle small" onClick={paginationClick(1, 2)}/>
          </div>
          <div className='section3-preview'>
            <Slider {...settingsImg} ref={_sliderImg} className='section2-slider-img'>
              {Array(5).fill({}).map((item: any, i: number) => (
                <div className='section2-slider-img-item' key={i}>
                  <img src={slides[i]} alt="slide"/>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        <div className='d-flex d-xl-none flex-column align-items-center'>
          {Array(5).fill({}).map((item: any, i: number) => (
            <div className='section2-item' key={i}>
              <p>
                {t(`SECTION2_TEXT_${i + 1}`)}
                <span className="section2-item-idx">0{i + 1}</span>
              </p>
              <img src={slides[i]} alt="slide"/>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Section_2;