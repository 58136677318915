import React, {FC, useCallback, useState} from 'react';
import {API} from '../../utils/api';
import Button, {CircleButton} from '../../components/button';
import {toast} from '../../utils/utils';
import {useTranslation} from "react-i18next";
import {Input, Textarea} from "../../components/FormControls";
import {EService, IRegisterRequest, IYoutuberApplicationSubmitRequest} from "../../utils/rest";
import {login_href} from "../../data/constants";
import Spinner from "../../components/spinner";

interface Props {
  setSuccessForm: (status: boolean) => void;
}


const RegistrationForm: FC<Props> = ({setSuccessForm}) => {
  const {t} = useTranslation();
  const [contacts, setContacts] = useState({whatsapp: '', tg: '', viber: ''});
  const [contactsError, setContactsError] = useState(false);
  const [form, setForm] = useState<IRegisterRequest>({email: '', password: ''});

  const [loading, setLoading] = useState(false);

  const handleChange = useCallback((key: string) => (e: any) => {
    setForm(prevState => {
      return {...prevState, [key]: e.target.value}
    })
  }, []);

  const handleChangeContacts = useCallback((contact: string) => (e: any) => {
    setContactsError(false);
    setContacts(prevState => {
      return {...prevState, [contact]: e.target.value}
    })
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (loading) return null;
    setLoading(true);
    try {
      const {token} = await API.Users.register({
        ...form,
        // contacts: resContacts,
        // links: form.links?.split('\n'),
        service: EService.TubePays,
        source: window.localStorage.getItem('source'),
        // ref: window.localStorage.getItem('ref'),
      });
      // @ts-ignore
      // window.ym(91359429, 'reachGoal', 'reg');
      if (token) {
        window.location.href = `${login_href}as/${token}`
      } else {
        setSuccessForm(true);
      }
    } catch (e: any) {
      toast(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form className="registration" onSubmit={handleSubmit}>

      <h3 className='mt-3 mb-20'>{t('REGISTRATION')}</h3>
      <div className='text-semiBold mb-40'>{t('FILL_REGISTRATION')}</div>
      <div className="row">
        <div className="col-12 col-lg-6">
          <Input
            required
            type='email'
            label={t('EMAIL') || ''}
            placeholder={t('EMAIL_PLACEHOLDER') || ''}
            value={form.email}
            onChange={handleChange('email')}
          />
        </div>
        <div className="col-12 col-lg-6">
          <Input
            type='password'
            label={t('PASSWORD') || ''}
            placeholder={t('PASSWORD_PLACEHOLDER') || ''}
            required
            value={form.password}
            onChange={handleChange('password')}
          />
        </div>
      </div>
      <div className='d-flex align-items-center'>
        <div className='position-relative d-flex align-items-center justify-content-center'>
          {loading ? <Spinner/> : null}
          <CircleButton type="submit" text='CREATE_ACCOUNT'/>
        </div>
      </div>
    </form>
  );
};

export default RegistrationForm;
