import React, {FC, useCallback} from 'react';
import {useTranslation} from "react-i18next";
import {CircleButton} from "../../components/button";
import {login_href} from "../../data/constants";

interface Props {
}

const Section_5: FC<Props> = () => {
  const {t} = useTranslation();

  return (
    <section id='section-5'>
      <div className='container section-5 d-flex align-items-xl-center align-items-start flex-column flex-lg-row'>
        <h3 className='mb-0 pe-0 pe-xl-4 mb-4 mb-xl-0 me-0 me-lg-4' dangerouslySetInnerHTML={{__html: t('SECTION5_TITLE') || ''}}
            style={{maxWidth: 950}}/>
        <a href={login_href} target={'_blank'}>
          <CircleButton text='JOIN_US_TEXT'/>
        </a>
      </div>
    </section>
  );
}

export default Section_5;