import React, {FC, useCallback} from 'react';
import './section-3.css';
import './section-media.css';
import {useTranslation} from "react-i18next";
import slide_1 from './assets/slide_1.png';
import slide_2 from './assets/slide_2.png';
import slide_3 from './assets/slide_3.png';
import youtube from './assets/youtube.png';
import Logo from "../../../components/Logo";
import {useNavigate} from "react-router-dom";
import {CircleButton} from "../../../components/button";

interface Props {

}

const Section_3: FC<Props> = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const handleRegistration = useCallback(() => {
    navigate('/registration')
  }, [navigate]);

  return (
    <section id='section-3'>
      <div className='container section-3'>
        <h3 className='text-center mb-20' dangerouslySetInnerHTML={{__html: t('SECTION3_TITLE') || ''}}/>
        <h4 className='mb-80 text-center' dangerouslySetInnerHTML={{__html: t('SECTION3_TEXT') || ''}}/>

        {/*<div className='section3-items accent-glow'>*/}
        {/*  <div className="section3-item item-1 ">*/}
        {/*    <div className="section3-item-title">*/}
        {/*      <div className="section3-item-idx">01</div>*/}
        {/*      <p className='text-muted'>{t('SECTION2_SLIDE_TEXT_1')}</p>*/}
        {/*    </div>*/}
        {/*    <div className="col-12 col-lg-8 d-flex justify-content-end">*/}
        {/*      <img src={slide_1} alt="slide" className='section3-item-img' style={{maxHeight: 320}}/>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  <div className="section3-item item-2">*/}
        {/*    <div className="section3-item-title">*/}
        {/*      <div className="section3-item-idx">02</div>*/}
        {/*      <p className='text-muted'>{t('SECTION2_SLIDE_TEXT_2')}</p>*/}
        {/*    </div>*/}
        {/*      <img src={slide_2} alt="slide" className='section3-item-img' style={{maxHeight: 702}}/>*/}
        {/*  </div>*/}
        {/*  <div className="section3-item mb-3 mb-lg-0 item-3">*/}
        {/*    <div className="section3-item-title">*/}
        {/*      <div className="section3-item-idx">03</div>*/}
        {/*      <p className='text-muted'>{t('SECTION2_SLIDE_TEXT_3')}</p>*/}
        {/*    </div>*/}
        {/*    <img src={slide_3} alt="slide" className='section3-item-img'/>*/}
        {/*  </div>*/}
        {/*  <div className="section3-item mb-3 mb-lg-0 flex-grow-1 item-4">*/}
        {/*    <div className="section3-item-title">*/}
        {/*      <div className="section3-item-idx">04</div>*/}
        {/*      <p className='text-muted'>{t('SECTION2_SLIDE_TEXT_4')}</p>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}

        <div className="row mb-4">
          <div className="col-8">
            <div className="section3-item item-1 h-100">
              <div className="section3-item-title">
                <div className="section3-item-idx">01</div>
                <p className='text-muted' style={{maxWidth: 200}}>{t('SECTION2_SLIDE_TEXT_1')}</p>
              </div>
              <div className="section3-item-youtube bg-circles">
                <img src={youtube} alt="youtube"/>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="section3-item item-1 h-100">
              <div className="section3-item-title">
                <div className="section3-item-idx">02</div>
                <p className='text-muted'>{t('SECTION2_SLIDE_TEXT_2')}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-5">
            <div className="section3-item item-1 ">
              <div className="section3-item-title">
                <div className="section3-item-idx">03</div>
                <p className='text-muted'>{t('SECTION2_SLIDE_TEXT_3')}</p>
              </div>
            </div>
          </div>
          <div className="col-7">
            <div className="section3-item item-1 h-100">
              <div className="section3-item-title">
                <div className="section3-item-idx">04</div>
                <p className='text-muted'>{t('SECTION2_SLIDE_TEXT_4')}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="section3-item item-5 d-flex mb-0 align-items-center">
          <div className='position-relative bg-circles'>
            <div className='section3-item-logo '>
              <Logo/>
            </div>
          </div>
          <div className="section3-item-title">
            <div className="section3-item-idx mt-0">05</div>
            <p className='text-muted' style={{maxWidth: 500}}>{t('SECTION2_SLIDE_TEXT_5')}</p>
          </div>
          <CircleButton onClick={handleRegistration}/>
        </div>
      </div>
    </section>
  );
}

export default Section_3;